import GoogleLoginButton from "./googleLogin";
import React from "react";
import styled from "styled-components";

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure this is high enough */
`;

const PopupContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  min-width: 300px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

function LoginPopUp({ onClose }) {
  // Function to handle the backdrop click
  const handleBackdropClick = (e) => {
    // Prevent closing if clicking inside the content area
    if (e.target !== e.currentTarget) return;
    onClose();
  };

  return (
    <Popup onClick={handleBackdropClick}>
      <PopupContent onClick={(e) => e.stopPropagation()}>
        <h4>Login</h4>
        <CloseButton onClick={onClose}>X</CloseButton>
        <GoogleLoginButton onSuccess={onClose} />
      </PopupContent>
    </Popup>
  );
}

export default LoginPopUp;
