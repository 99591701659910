// src/store/exampleSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  userId: null,
  fullName: null,
  firstName: null,
  lastName: null,
  email: null,
  imageUrl: null,
}


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedInStatus: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUserData: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.userId = action.payload.userId;
      state.fullName = action.payload.firstName + " " + action.payload.lastName;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.imageUrl = action.payload.imageUrl;
    },
    resetState: (state) => {
      return {...initialState}
    }
  },
});

export const { setLoggedInStatus, setUserData, resetState } = userSlice.actions;

export default userSlice.reducer;
