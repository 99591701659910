import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useUserState, { useUserData } from "../../hooks/useUserData";

import { FETCH_ALL_COMMUNITIES } from "../../constants";
import { apiHelper } from "../../utils/apiHelper";
import styled from "styled-components";

// Hamburger.js
const HamburgerMenu = ({ onClick }) => (
  <Hamburger onClick={onClick}>
    <span></span>
    <span></span>
    <span></span>
  </Hamburger>
);

const SideDrawer = ({ show, onClose, login }) => {
  const [topCommunities, setTopCommunities] = useState([]);
  const navigate = useNavigate();
  const userData = useUserData();

  const { logoutUser } = useUserState();

  const handleLogout = () => {
    logoutUser();
    onClose();
    navigate(`/`)
  } 

  const handleLoginClick = () => {
    onClose();
    login();
  };

  useEffect(() => {
    let url = FETCH_ALL_COMMUNITIES
    apiHelper({ url })
      .then((response) => {
        setTopCommunities(response.data)
      })
      .catch((error) => {
        // alert("something went wrong, we are looking into it");
      });
  }, []);

  return (
    <>
      {show && <Backdrop onClick={onClose} />}
      <Drawer show={show.toString()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <DrawerLinks>
          <ProfileContainer>
            {userData?.isLoggedIn ? (
              <>
                <ProfileImage src={userData.imageUrl} alt="Profile" />
                <Username>{userData.fullName}</Username>
              </>
            ) : (
              <>
                <LoginButton onClick={handleLoginClick}>Login</LoginButton>
              </>
            )}

          </ProfileContainer>
          <CommunityContainer>
            <h2>Top Communities</h2>
            <CommunityList>
              {topCommunities.slice(0, 5).map((community) => (
                <Community key={community.id}><Avatar src={community.image} alt={`${community.name} avatar`} /> <p onClick={() => { navigate(`/community/${community.url}`); onClose() }}>{community.name}</p></Community>
              ))}
            </CommunityList>
            <Link to={'/communities'}><h5>View all communities</h5></Link>
          </CommunityContainer>
        </DrawerLinks>
        { userData?.isLoggedIn && <LogoutButton onClick={handleLogout}>Logout</LogoutButton> }
      </Drawer>
    </>
  )
};

export { HamburgerMenu, SideDrawer };


const LogoutButton = styled.button`
  background-color: #dab8e8;
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  font-size: 16px;

  @media (max-width: 900px) {
    margin-bottom: 6vh;
  }
`;

const LoginButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const CommunityContainer = styled.div`

  h2{
    font-size: 1.2rem;
  }
`

const CommunityList = styled.div`

display: flex;
flex-direction: column;
row-gap: .4rem;

`

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 25px;
  margin-right: 15px;
`;

const Community = styled.div`

  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  cursor: pointer;
`



const Hamburger = styled.div`
  width: 30px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  span {
    height: 4px;
    background-color: black;
    border-radius: 2px;
  }
`;


const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  height: 100%;
  color: black;
  background-color: white;
  padding: 20px;
  transform: ${({ show }) => (show == "true" ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;

  @media (max-width: 481px) {
    width: 250px;
  }
`;

const CloseButton = styled.div`
  font-size: 24px;
  cursor: pointer;
  color: black;
  margin-bottom: 20px;
`;

const DrawerLinks = styled.div`
  flex: 1;

  a {
    color: black;
    text-decoration: none;
    margin-bottom: 10px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
`;

const Username = styled.h1`
  font-size: 24px;
  margin: 0 10px 0 0;

  @media (max-width: 481px) {
    font-size: 16px;
  }
`;

const ProfileImage = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #fff;

  @media (max-width: 682px) {
    width: 50px;
    height: 50px;
  }
`;
