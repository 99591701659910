import { FETCH_PUBLIC_USER_PROFILE, FETCH_TRIPS_BY_PUBLIC_PROFILES } from "../../../constants";
import React, { useEffect, useState } from 'react';

import BottomBar from "../../navigation/BottomBar";
import { CenterContentContainer } from "../../containers/utils";
import Navbar from "../../navigation/NavBar";
import NotFound from "../errors/NotFound";
import { ToggleButton } from "../../HomePage/buttons";
import TripCardMini from "../../ui/TripCard/TripCardMini";
import { apiHelper } from "../../../utils/apiHelper";
import styled from 'styled-components';
import { useParams } from "react-router-dom";

const ProfileContainer = styled.div`
  display: flex;
  flex: 1;
  color: #000;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 1rem;
  column-gap: 1rem;
  background-color: #fff;
  border-radius: 10px;

  @media (max-width: 725px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
    margin-bottom: 0;
  }
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #fff;

  @media (max-width: 682px) {
    width: 100px;
    height: 100px;
  }
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const UsernameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Username = styled.h1`
  font-size: 24px;
  margin: 0 10px 0 0;

  @media (max-width: 481px) {
    font-size: 16px;
  }
`;

const BioSection = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px;

  @media (max-width: 542px) {
    padding: 5px;
    margin-bottom: 20px;
    margin-top: 5px;
    font-size: 10px;
  }
`;

const BioText = styled.p`
  font-size: 16px;
  color: #000;

  @media (max-width: 542px) {
    font-size: 12px;
  }
`;

const Stats = styled.div`
  display: flex;
  margin-bottom: 1rem;

  @media (max-width: 494px) {
    flex-direction: column;
  }
`;

const Stat = styled.div`
  margin-right: 20px;
`;

const StatNumber = styled.span`
  font-weight: bold;

  @media (max-width: 481px) {
    font-size: 14px;
  }
`;

const Bio = styled.p`
  font-size: 16px;
  margin-top: 0;
`;

const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
`;

const PostsContainerWithFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
`;


const COMING_TRIPS = "Coming Trips"
const PAST_TRIPS = "Past Trips"

const PublicProfile = () => {
    const params = useParams();
    const username = params.username;
    const [tripsList, setTripsList] = useState([]);
    const [profileData, setProfileData] = useState([]);
    const [activeSection, setActiveSection] = useState(COMING_TRIPS);

    const [publicProfileAvailable, setPublicProfileAvailable] = useState(true);

    useEffect(() => {
      apiHelper(
        {
          url: FETCH_PUBLIC_USER_PROFILE + username
        }
      ).then((response) => {
        setProfileData(response.data);
      }).catch((error) => {
        console.log("user not found");
        setPublicProfileAvailable(false);
      })
    }, []);

    useEffect(() => {
      let pastTrips = "true";
      if (activeSection == COMING_TRIPS) {
        pastTrips = "false";
      }

      let replacements = [username, pastTrips];

      apiHelper({
        url: FETCH_TRIPS_BY_PUBLIC_PROFILES.replace(/%s/g, () => replacements.shift())
      })
      .then((response) => {
        setTripsList(response.data);
      })
      .catch((error) => {
        console.log("error in fetching trips details")
      })
    }, [activeSection]);

    if (!publicProfileAvailable) {
      return <NotFound />
    }

    const PublicUserComponent = () => (
        <>
            <PostsContainerWithFilter>
                <CenterContentContainer>
                    <ToggleButton isactive={activeSection == COMING_TRIPS} onClick={() => setActiveSection(COMING_TRIPS)}>Coming Trips</ToggleButton>
                    <ToggleButton isactive={activeSection == PAST_TRIPS} onClick={() => setActiveSection(PAST_TRIPS)}>Past Trips</ToggleButton>
                </CenterContentContainer>
                <PostsContainer>
                    {tripsList.map((trip) => (
                        <TripCardMini key={trip.id} tripDetails={trip} />
                    ))}
                </PostsContainer>
            </PostsContainerWithFilter>
        </>
    );

    return (
        <>
            <Navbar />
            <ProfileContainer>
                <Content>
                    <Header>
                        <ProfileImage src={profileData?.image_url} alt="Profile" />
                        <UserDetails>
                            <UsernameContainer>
                                <Username>{profileData.name}</Username>
                            </UsernameContainer>
                            <Stats>
                                <Stat>
                                    <StatNumber>{profileData.instagram_followers}</StatNumber> instagram followers
                                </Stat>
                                <Stat>
                                    <StatNumber>806</StatNumber> 
                                </Stat>
                            </Stats>
                        </UserDetails>
                    </Header>
                    <BioSection>
                        <BioText>{profileData.description}</BioText>
                    </BioSection>
                    <PublicUserComponent />
                </Content>
            </ProfileContainer>
            <BottomBar />
        </>
    );
}

export default PublicProfile;