import { resetState, setUserData } from "../store/userSlice";
import { useDispatch, useSelector } from 'react-redux';

import { FETCH_PRIVATE_USER_PROFILE } from "../constants";
import { apiHelper } from "../utils/apiHelper";

// Selector to get user data from the Redux store
const selectUserData = (state) => state.user;

// Utility function to get user data
export const useUserData = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const fetchUserData = async () => {
    try {
      const response = await apiHelper({
        url: FETCH_PRIVATE_USER_PROFILE
      });

      if (response.success) {
        const newUserData = {
          isLoggedIn: true,
          userId: response.data.id,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email,
          imageUrl: response.data.image_url
        }

        dispatch(setUserData(newUserData));

        return newUserData
      }

      return null;
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      return null;
    }
  };

  const soccalToken = localStorage.getItem("soccalToken");
  if (!soccalToken || soccalToken.trim() === "") {
    return null;
  }


  // Check if user data is already in the state
  if (userData.isLoggedIn) {
    return userData;
  } else {
    return fetchUserData();
  }
};


const useUserState = () => {
  const dispatch = useDispatch();

  const logoutUser = () => {
    localStorage.removeItem("soccalToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userLoggedIn");

    dispatch(resetState());
  }

  const setUserLoginData = (data) => {
    localStorage.setItem("soccalToken", data.token);
    localStorage.setItem("userId", data.user_details.id);
    localStorage.setItem("userLoggedIn", true);

    const userData = {
      isLoggedIn: true,
      userId: data.user_details.id,
      firstName: data.user_details.first_name,
      lastName: data.user_details.last_name,
      email: data.user_details.email,
      imageUrl: data.user_details.image_url
    }

    dispatch(setUserData(userData));

  }

  return {
    logoutUser,
    setUserLoginData
  }
}

export default useUserState