import React, { useEffect, useState } from "react";

import BottomBar from "../../navigation/BottomBar";
import Navbar from "../../navigation/NavBar";
import {
    TopPostPageContainer
} from "../../containers/ThreeColumnContainer";
import TopPosts from "../../HomePage/TopPosts";

function TopPostsPage() {


    return (
        <>
            <Navbar />
            <TopPostPageContainer>

                <TopPosts></TopPosts>

            </TopPostPageContainer>
            <BottomBar />
        </>
    );
}

export default TopPostsPage;
