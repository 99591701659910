// src/components/Sidebar.js

import React from 'react';
import styled from 'styled-components';
import { FaHome, FaSearch, FaCompass, FaFilm, FaHeart, FaUser } from 'react-icons/fa';

const SidebarContainer = styled.div`
  width: 250px;
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 33px;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
`;

const SideBar = () => {
  return (
    <SidebarContainer>
      <NavItem>
        <FaHome size={20} />
        <span>Home</span>
      </NavItem>
      <NavItem>
        <FaSearch size={20} />
        <span>Search</span>
      </NavItem>
      <NavItem>
        <FaCompass size={20} />
        <span>Trips</span>
      </NavItem>
      {/* <NavItem>
        <FaFilm size={20} />
        <span>Reels</span>
      </NavItem>
      <NavItem>
        <FaHeart size={20} />
        <span>Notifications</span>
      </NavItem> */}
      <NavItem>
        <FaUser size={20} />
        <span>Profile</span>
      </NavItem>
    </SidebarContainer>
  );
};

export default SideBar;
