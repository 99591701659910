import {
  Container,
  LeftSidebar,
  MainContent,
  RightSidebar,
  SideBarTopPostContainer,
} from "../../containers/ThreeColumnContainer";
import { FETCH_COMMUNITY_META_DATA, FETCH_COMMUNITY_TRIPS } from "../../../constants";
import React, { useEffect, useState } from "react";

import BottomBar from "../../navigation/BottomBar";
import CommunityHeaderComponent from "./CommunityHeader";
import Navbar from "../../navigation/NavBar";
import TopHosts from "../../HomePage/TopHosts";
import TopPosts from "../../HomePage/TopPosts";
import TripCard from '../../ui/TripCard/TripCard';
import { apiHelper } from "../../../utils/apiHelper";
import { useParams } from "react-router-dom";

function CommunityPage() {
  const [tripsList, setTripsList] = useState([]);
  const [communityMetaData, setCommunityMetaData] = useState(null);
  const params = useParams()

  useEffect(() => {
    apiHelper({
      url: FETCH_COMMUNITY_TRIPS + `${params.community_slug}/trips?pastTrips=false`
    })
      .then((response) => {
        setTripsList(response.data);
      })
      .catch((error) => {
        // TODO: do a proper error handling error displaying to user
        // alert("something went wrong, we are looking into it");
      })

    apiHelper({
      url: FETCH_COMMUNITY_META_DATA + `${params.community_slug}/meta`
    })
      .then((response) => {
        setCommunityMetaData(response.data);
      })
      .catch((error) => {
        // TODO: do a proper error handling error displaying to user
        // alert("something went wrong, we are looking into it");
      })
  }, [params]);



  return (
    <>
      <Navbar />
      {communityMetaData ? (
        <CommunityHeaderComponent metadata={communityMetaData} />
      ) : (
        <p>loading details for you ...</p> // replace this shit with shimmer in future
      )}
      <Container>
        <LeftSidebar>
          <TopHosts></TopHosts>
        </LeftSidebar>
        <MainContent>
          {tripsList.map((trip) => (
            <TripCard
              key={trip.id}
              tripDetails={trip}
            />
          ))}
        </MainContent>
        <RightSidebar>
          <SideBarTopPostContainer>
            <TopPosts></TopPosts>
          </SideBarTopPostContainer>

        </RightSidebar>
      </Container>
      <BottomBar />
    </>
  );
}

export default CommunityPage;
