import React from 'react';
import styled from 'styled-components';

// Styled components
const FilterButton = styled.button`
  width: 150px;
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  height: 35px;
  border-radius: 10px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.192);
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.021);
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.11);
    background-color: rgb(59, 59, 59);

    svg {
      fill: white;
    }
  }
`;

const FilterIcon = styled.svg`
  height: 16px;
  fill: rgb(77, 77, 77);
  transition: all 0.3s;
`;

// Component
const FilterButtonComponent = ({ onClick }) => {
  return (
    <FilterButton title="filter" onClick={onClick}>
      <span>Filter By Date</span>
      <FilterIcon viewBox="0 0 512 512" height="1em">
        <path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z" />
      </FilterIcon>
    </FilterButton>
  );
};

export default FilterButtonComponent;
