import {
  Container,
  MainContent,
} from "../../containers/ThreeColumnContainer";
import { FETCH_ALL_COMMUNITIES, FETCH_FOLLOWED_COMMUNITIES } from "../../../constants";
import React, { useEffect, useState } from "react";

import BottomBar from "../../navigation/BottomBar";
import { CenterContentContainer } from "../../containers/utils";
import CommunityListingCard from "./CommunityListingCard";
import Navbar from "../../navigation/NavBar";
import SearchBar from "./SearchBar";
import { ToggleButton } from "../../HomePage/buttons";
import { apiHelper } from "../../../utils/apiHelper";

const YOUR_COMMUNITIES = "your_communties"
const ALL_COMMUNITIES = "all_communities"

function CommunityDiscovery() {

  const [communitiesList, setCommunitiesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeCommunitySection, setActiveCommunitySection] = useState(ALL_COMMUNITIES);



  let request_url = activeCommunitySection == YOUR_COMMUNITIES ? FETCH_FOLLOWED_COMMUNITIES : FETCH_ALL_COMMUNITIES
  useEffect(() => {
    apiHelper({
      url: request_url
    })
      .then((response) => {
        setCommunitiesList(response.data)
      })
      .catch((error) => {
      })
  }, [activeCommunitySection]);


  const filteredCommunities = communitiesList.filter(
    (trip) => trip.name && trip.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <Container>
        <MainContent>
          <CenterContentContainer>
            <ToggleButton isactive={activeCommunitySection == ALL_COMMUNITIES} onClick={() => setActiveCommunitySection(ALL_COMMUNITIES)}>All Communities</ToggleButton>
            <ToggleButton isactive={activeCommunitySection == YOUR_COMMUNITIES} onClick={() => setActiveCommunitySection(YOUR_COMMUNITIES)} >Your Communities</ToggleButton>
          </CenterContentContainer>
          <SearchBar value={searchQuery} onChange={setSearchQuery} placeholder={"Search for Community"} />
          {filteredCommunities.map((community) => (
            <CommunityListingCard
              community={community}
            />
          ))}
        </MainContent>
      </Container>
      <BottomBar />
    </>
  );
}

export default CommunityDiscovery;
