import React, { useEffect, useState } from 'react';

import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
    const [introSeen, setIntroSeen] = useState(() => {
        return JSON.parse(localStorage.getItem("introSeen"));
    });

    useEffect(() => {
        const updatedIntroSeen = JSON.parse(localStorage.getItem("introSeen"));
        setIntroSeen(updatedIntroSeen);
    }, []);

    if (!introSeen) {
        return <Navigate to="/onboarding" />;
    }

    return element;
};

export default ProtectedRoute;
