import React, { useEffect, useState } from "react";

import { FETCH_HOME_FEED_TRIPS } from "../../constants";
import TripCardTopPosts from "../ui/TripCard/TripCardTopPost";
import { apiHelper } from "../../utils/apiHelper";
import styled from 'styled-components';

const Container = styled.div`
    display: flex; 
    flex-direction: column;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;

`
const TopPosts = () => {

    const [tripsList, setTripsList] = useState([]);

    // TODO: add a shimmer while loading posts
    useEffect(() => {
        apiHelper({
            url: FETCH_HOME_FEED_TRIPS
        })
            .then((response) => {
                setTripsList(response.data);
            })
            .catch((error) => {
                // TODO: do a proper error handling error displaying to user
                // alert("something went wrong, we are looking into it");
            })
    }, []);

    return (
        <>
            {tripsList.map((trip) => (
                <TripCardTopPosts
                    key={trip.id}
                    tripDetails={trip}
                />
            ))}
        </>
    )
}

export default TopPosts