import React, { useEffect, useState } from 'react';

import { FETCH_ALL_COMMUNITIES } from '../../constants';
import { apiHelper } from '../../utils/apiHelper';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const JoinCommunities = () => {
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    apiHelper({
      url: FETCH_ALL_COMMUNITIES
    })
      .then((response) => {
        console.log(response.data);
        setSelectedCommunities(response.data);
      })
      .catch((error) => {
        // TODO: do a proper error handling error displaying to user
        // alert("something went wrong, we are looking into it");
      })
  }, []);

  // random list of cities

  const handleToggle = (community_id) => {
    let newCommunities = [...selectedCommunities];
    let index = newCommunities.findIndex((community) => community.id === community_id);
    newCommunities[index].isSelected = !newCommunities[index].isSelected;
    setSelectedCommunities(newCommunities);
  };

  const handleComplete = async () => {
    // if (selectedCommunities.length === 0) {
    //     alert("Please select at least one community.");
    //     return;
    // }

    // apiHelper({
    //     url: SAVE_USER_INTERESTS,
    //     method: "POST",
    //     data: inputBody,
    //   })
    //     .then((response) => {
    //       console.log("successfully saved");
    //       console.log(response);
    //       // alert("thanks for submitting your details we will look into it");
    //       onClose();
    //     })
    //     .catch((error) => {
    //       console.log("error while saving the data");
    //       console.log(error);
    //       // alert("something went wrong we are looking into it")
    //     });

    navigate('/');


  };

  return (
    <Container>
      <SubContainer>
        <Title>Join Communities</Title>
        <Subtitle>communities where all events happening across the city are listed</Subtitle>
        <CommunityContainer>
          {selectedCommunities.map((community, index) => (
            <CommunityButton
              key={index}

              onClick={() => handleToggle(community.id)}
            >
              {community.name}
            </CommunityButton>
          ))}
        </CommunityContainer>
      </SubContainer>

      <CompleteButton onClick={handleComplete}>Complete</CompleteButton>
    </Container>
  );
};

export default JoinCommunities;

const Container = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    row-gap: 1rem;
    align-items: center;
`;

const SubContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
 
  row-gap: .6rem;
  
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 0;

`;

const CommunityContainer = styled.div`


display: flex;
flex-wrap: wrap;
justify-content: center;
row-gap: 8px;
margin-bottom: 20px;
column-gap: 8px;
`;

const CommunityButton = styled.button`
  padding: 10px 15px;
  border-radius: 20px;
  border: 2px solid ${(props) => (props.isSelected ? '#a065d3' : '#c4c4c4')};
  background-color: ${(props) => (props.isSelected ? '#a065d3' : '#f0f0f0')};
  color: ${(props) => (props.isSelected ? '#fff' : '#333')};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
  

  &:hover {
    background-color: ${(props) => (props.isSelected ? '#a065d3' : '#ddd')};
    border-color: #a065d3;
  }
`;

const CompleteButton = styled.button`
  width: 80%;
  padding: 15px;
  border-radius: 30px;
  background-color: #a065d3;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  margin-bottom: 1rem;

  &:hover {
    background-color: #8a50c1;
  }
`;
