import React, { useEffect } from "react";
import { GOOGLE_LOGIN_API } from "../../constants";
import useUserState from "../../hooks/useUserData";
import { apiHelper } from "../../utils/apiHelper";

const GoogleLoginButton = ({ onSuccess }) => {
  const { setUserLoginData } = useUserState();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const sendTokenToBackend = (token) => {
    apiHelper(
      {
        url: GOOGLE_LOGIN_API,
        method: 'POST',
        data: {
          credential: token
        },
        headers: {
          "Content-Type": "application/json",
        }
      }
    ).then((response) => {
      setUserLoginData(response.data);
      onSuccess();
    }).catch((error) => {
      console.error("Error sending token to backend:", error);
    });
  };

  window.validateToken = (response) => {
    sendTokenToBackend(response.credential);
    // below here we can call the validate api of backend
    // backend either can set cookie or return some jwt which we can set to our localstorage
    // setting cookie might be tough in dev environment as both BE and FE are running on different ports
  };

  return (
    <>
      <div
        id="g_id_onload"
        data-client_id="32295139328-0q632e7euebgbocus956u7563htc0skg.apps.googleusercontent.com"
        data-context="signin"
        data-ux_mode="popup"
        data-callback="validateToken"
        data-nonce=""
        data-auto_prompt="false"
      ></div>

      <div
        class="g_id_signin"
        data-type="standard"
        data-shape="pill"
        data-theme="filled_blue"
        data-size="large"
        data-logo_alignment="left"
      ></div>
    </>
  );
};

export default GoogleLoginButton;
