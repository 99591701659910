import { FETCH_FOLLOWED_COMMUNITIES, FETCH_USER_WISHLISTED_TRIPS } from "../../../constants";
import { useEffect, useState } from "react";

import BeHostButton from "../../UserProfile/BeHostCard";
import BottomBar from "../../navigation/BottomBar";
import { CenterContentContainer } from "../../containers/utils";
import CommunityListingCard from "../community/CommunityListingCard";
import Navbar from "../../navigation/NavBar";
import SideBar from "./SideBar";
import { StyledLink } from "../../ui/random";
import { ToggleButton } from "../../HomePage/buttons";
import TripCardMini from "../../ui/TripCard/TripCardMini";
import { apiHelper } from "../../../utils/apiHelper";
import styled from 'styled-components';
import { useUserData } from "../../../hooks/useUserData";

const ProfileContainer = styled.div`
  display: flex;
  flex: 1;
  color: #000;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
`;

const Header = styled.header`
  margin: 20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 50%;
  margin-bottom: 1rem;
  column-gap: 1rem;

  @media (max-width: 725px) {
    max-width: 90%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
    margin-bottom: 0;
  }
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #fff;

  @media (max-width: 682px) {
    width: 100px;
    height: 100px;
  }
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const UsernameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Username = styled.h1`
  font-size: 24px;
  margin: 0 10px 0 0;

  @media (max-width: 481px) {
    font-size: 16px;
  }
`;

const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
`;

const PostsContainerWithFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
`;

const WISHLISTED_TRIPS = "Wishlisted Trips";
const YOUR_COMMUNITIES = "Your communities";


const PrivateProfile = () => {
  const [tripsList, setTripsList] = useState([]);
  const [followedCommunities, setFollowedCommunities] = useState([]);
  const [activeSection, setActiveSection] = useState(WISHLISTED_TRIPS);
  const userData = useUserData();

  useEffect(() => {
    if (activeSection != WISHLISTED_TRIPS) {
      return;
    }

    apiHelper({ url: FETCH_USER_WISHLISTED_TRIPS })
      .then((response) => {
        setTripsList(response.data)
      })
      .catch((error) => {
        console.log("fucking error bitch")
        console.log(error)
        // alert("something went wrong, we are looking into it");
      });
  }, [activeSection]);

  useEffect(() => {
    if (activeSection != YOUR_COMMUNITIES) {
      return
    }
    apiHelper({ url:  FETCH_FOLLOWED_COMMUNITIES })
      .then((response) => {
        setFollowedCommunities(response.data)
      })
      .catch((error) => {
        // alert("something went wrong, we are looking into it");
      });
  }, [activeSection]);

  const TripsContainer = () => (
    <PostsContainer>
      {tripsList.length == 0 ? (<div>start following some trips bitch</div>) : (<>
        {tripsList.map((trip) => (
          <TripCardMini key={trip.id} tripDetails={trip} />
        ))}
      </>)}
    </PostsContainer>
  );

  const FollowedCommunitiesContainer = () => (
    <>
      {followedCommunities.length == 0 ? (<div>follow something you bitch</div>) : (<>{followedCommunities.map((community) => (
        <StyledLink key={community.id}>
          <CommunityListingCard to={"/community/" + community.url}
            community={community}
          />
        </StyledLink>
      ))}
      </>)}
    </>
  )

  const PrivateProfileComponent = () => (
    <>
      <CenterContentContainer>
        <ToggleButton isactive={activeSection === WISHLISTED_TRIPS} onClick={() => setActiveSection(WISHLISTED_TRIPS)}>Wishlisted Trips</ToggleButton>
        <ToggleButton isactive={activeSection === YOUR_COMMUNITIES} onClick={() => setActiveSection(YOUR_COMMUNITIES)}>Your Communities</ToggleButton>
      </CenterContentContainer>
      <PostsContainerWithFilter>
        {activeSection == WISHLISTED_TRIPS ? (<TripsContainer />) : (<FollowedCommunitiesContainer />)}
      </PostsContainerWithFilter>
    </>
  );

  return (
    <>
      <Navbar />
      <ProfileContainer>
        <Content>
          <Header>
            <ProfileImage src={userData?.imageUrl} alt="Profile" />
            <UserDetails>
              <UsernameContainer>
                <Username>{userData?.fullName}</Username>
              </UsernameContainer>
            </UserDetails>
          </Header>
          <PrivateProfileComponent />
        </Content>
      </ProfileContainer>
      <BottomBar />
    </>
  );
}


export default PrivateProfile;
