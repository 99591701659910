import styled from 'styled-components';

export const ToggleButton = styled.button`
  color: ${(props) => {
    if (props.isactive) {
      return "black";
    } else {
      return "grey";
    }
  }};

  width: 150px;
  height: 35px;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: all .1s;
  font-weight: bold;

  border-bottom: ${(props) => (props.isactive ? "3px solid #b280c9" : "none")};
`;