import { FETCH_FOLLOWED_COMMUNITIES, FOLLOW_COMMUNITY, LEAVE_COMMUNITY } from '../../../constants';
// HeaderComponent.js
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiHelper } from '../../../utils/apiHelper';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const HeaderContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
  color: white;
  row-gap: 1rem;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Title = styled.h1`
  font-size: 24px;
  color : black;
  margin: 0;
`;

const DescriptionBox = styled.div`
  background-color: #2b08e280;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  line-height: 1.75rem;
  word-spacing: .08rem;

  p{
    margin : 0;
  }
`;

const FollowersCount = styled.div`
  margin-top: 10px;
  font-size: 18px;
  color: black;
`;

const CommunityDetail = styled.div`
    display:flex;
    width: 100%;
    row-gap: 10rem;
    justify-content: space-between;
`
const NameImageContainer = styled.div`
    display:flex;
    column-gap: 1rem;
    justify-content: space-between;
    align-items: center;
`

const ClickButton = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.192);
  padding: .5rem 2.75rem;
  cursor: pointer;
  background-color: ${(props) => (props.following ? '#f0f0f0' : '#b280c9')};
  border-radius: 1rem;
`

const CommunityHeaderComponent = ({ metadata }) => {
  // TODO: instead of initializing the following always as false fetch it from a hook or state
  const [following, setFollowing] = useState(false);

  useEffect(() => {
    apiHelper({
      url: FETCH_FOLLOWED_COMMUNITIES,
      method: 'GET',
      data: {}
    }).then((response) => {
      const followedCommunities = response.data;
      const isFollowing = followedCommunities.some((community) => community.id === metadata.id);
      setFollowing(isFollowing);
    }).catch((error) => { });
  }, []);


  const handleJoinClick = (following, communityId) => {
    console.log('Button clicked');

    const data = {
      resource_type: "community",
      resource_id: communityId
    }

    let url = FOLLOW_COMMUNITY;
    if (following) {
      url = LEAVE_COMMUNITY
    }

    apiHelper({
      url: url,
      method: 'POST',
      data: data
    }).then((response) => {
      console.log("successfully followed");
      if (url === LEAVE_COMMUNITY) {
        setFollowing(false)
      } else {
        setFollowing(true)
      }
    }).catch((error) => {
      console.log("bc maa chud gayi");
    })
  };

  const description = metadata.description.trim();

  return (
    <HeaderContainer>
      <CommunityDetail> <NameImageContainer><ProfileImage src={metadata.image} alt="Profile" />
        <Title>{metadata.name}</Title> </NameImageContainer>
        {metadata.followers > 0 ? <FollowersCount><FontAwesomeIcon icon={faUser} /> {metadata.followers}</FollowersCount> : null} </CommunityDetail>
      <ClickButton
        following={following}
        onClick={() => handleJoinClick(following, metadata.id)}>{following ? 'Leave' : 'Join'}</ClickButton>
      {description.length > 0 ?
        <DescriptionBox>
          <p>{description}</p>
        </DescriptionBox>
        : null}
    </HeaderContainer>
  );
};

export default CommunityHeaderComponent;