import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';
import { styled } from 'styled-components';

const ImageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    z-index: 1; /* Lower z-index */
    height: ${({ source }) => {
    if (source === 'PDP') {
      return '30rem';
    }
    return "300px";
  }}; /* Dynamic height */
    

    @media (max-width: 620px) {

    height: ${({ source }) => {
    if (source === 'PDP') {
      return '25rem';
    }
    return "300px";
  }}; /* Dynamic height */
    
    
    }

    @media (max-width: 495px) {

    height: ${({ source }) => {
    if (source === 'PDP') {
      return '20rem';
    }
    return "300px";
  }}; /* Dynamic height */
    
    
    }

     @media (max-width: 412px) {

    min-height: ${({ source }) => {
    if (source === 'PDP') {
      return '18rem';
    }
    return "300px";
  }}; /* Dynamic height */
    
    
    }
  `;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PrevButton = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 2; /* Still lower than the popup */
  cursor: pointer;
`;

const NextButton = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 2; /* Still lower than the popup */
  cursor: pointer;
`;

const TripImageCarousel = ({ images, source }) => {
  let sliderRef;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    adaptiveHeight: true,
  };

  return (
    <ImageContainer source={source}>
      <Slider {...settings} ref={(slider) => (sliderRef = slider)}>
        {images.map((photo, index) => (
          <Image key={index} src={photo} alt={`trip image ${index + 1}`} />
        ))}
      </Slider>
      <PrevButton onClick={() => sliderRef.slickPrev()}>
        <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#fff" />
      </PrevButton>
      <NextButton onClick={() => sliderRef.slickNext()}>
        <FontAwesomeIcon icon={faChevronRight} size="2x" color="#fff" />
      </NextButton>
    </ImageContainer>
  );
};

export default TripImageCarousel;
