import React, { useState } from 'react';

import styled from 'styled-components';

// Sample itinerary data

// Styled Components
const Container = styled.div`
background-color: white;
  font-family: Arial, sans-serif;
`;

const DayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
`;

const DayNumber = styled.span`
  background-color: #c66a29;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 10px;


  @media (max-width: 465px) {
  min-width: 4.1rem;}


      
`;

const Title = styled.span`
  flex-grow: 1;
`;

const Description = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  word-spacing: 1.5px;
  font-size: 0.9rem;
  color: #646363;
  padding-top:0;
`;

const ArrowIcon = styled.span`
  font-size: 2rem;
  transform: ${(props) => (props.expanded ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: transform 0.2s;
  color: #c66a29;
`;

// Main Itinerary Component
const Itinerary = ({ itinerary }) => {
  console.log(itinerary)
  const [expandedDay, setExpandedDay] = useState(null);

  const toggleExpand = (day) => {
    setExpandedDay(expandedDay === day ? null : day);
  };

  return (
    <Container>
      {(itinerary || []).map(({ day, title, description }) => (
        <div key={day}>
          <DayHeader onClick={() => toggleExpand(day)}>
            <DayNumber>DAY {day}</DayNumber>
            <Title>{title}</Title>
            <ArrowIcon expanded={expandedDay === day}>›</ArrowIcon>
          </DayHeader>
          {expandedDay === day && <Description>{description}</Description>}
        </div>
      ))}
    </Container>
  );
};

export default Itinerary;
