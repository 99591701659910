import React from "react";
import {
  Container,
  LeftSidebar,
  MainContent,
  RightSidebar,
} from "../../containers/ThreeColumnContainer";
import Navbar from "../../navigation/NavBar";

function SearchPage() {
  return (
    <>
      <Navbar />
      <Container>
        <LeftSidebar>
          <div>launchingam</div>
        </LeftSidebar>
        <MainContent>
          <div>launchingam</div>
          <div>launchingam</div>
          <div>launchingam</div>
          <div>launchingam</div>
          <div>launchingam</div>
          <div>launchingam</div>
        </MainContent>
        <RightSidebar>
          <div>munchingam</div>
        </RightSidebar>
      </Container>
    </>
  );
}

export default SearchPage;
