import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';
import React from 'react';
import SubmitButton from '../button/SubmitButton';
import styled from 'styled-components';

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* Adjust as needed to ensure it's above all other content */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background to overlay the screen */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DateRangePickerContainer = styled.div`
  z-index: 10000;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  align-items: center; /* Center children horizontally */
`;

const DateRangePicker = ({ ranges, onChange, isOpen, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <>
      {isOpen && (
        <PopupContainer>
          <DateRangePickerContainer>
            <DateRange
              minDate={today}
              ranges={ranges}
              onChange={onChange}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
            />
            <SubmitButton text={"submit"} onClick={handleClose} />
          </DateRangePickerContainer>
        </PopupContainer>
      )}
    </>
  );
};

export default DateRangePicker;
