import React, { useEffect, useState } from "react";

import BottomBar from "../../navigation/BottomBar";
import Navbar from "../../navigation/NavBar";
import TopHosts from "../../HomePage/TopHosts";
import {
    TopHostsPageContainer
} from "../../containers/ThreeColumnContainer";

function TopHostsPage() {


    return (
        <>
            <Navbar />
            <TopHostsPageContainer>

                <TopHosts></TopHosts>

            </TopHostsPageContainer>
            <BottomBar />
        </>
    );
}

export default TopHostsPage;
