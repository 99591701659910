import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { formatINR } from '../../../utils/prices';
import styled from 'styled-components';

// Modal overlay styling
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  z-index: 1000;
`;

// Modal container styling
const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-height: 80vh;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  overflow-y: auto;
  z-index: 1001;
  flex-direction: column;
  align-items: center;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1059px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

// Styled Dates component
const Dates = styled.div`
  background-color: #d5ffd4;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  flex-basis: calc(33% - 20px);
  text-align: center;

  @media (max-width: 768px) {
    flex-basis: calc(50% - 20px);
  }
`;

// Close button styling
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

// Card styling
const Card = styled.div`
  width: 260px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  font-family: Arial, sans-serif;
  max-height: 40rem;
  background-color: white;

  display:flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 871px) {
    width: 40%;
    height: 26rem;
  }

  @media (max-width: 652px) {
    width: 60%;
  }


  @media (max-width: 542px) {
    width: 80%;
  }

  @media (max-width: 542px) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Location = styled.div`
  color: #888;
  font-size: 12px;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 16px;
`;

const Title = styled.h2`
  font-size: 18px;
  margin: 0 0 10px 0;
`;

const DateContainer = styled.div`
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #555;
  text-decoration: underline;
  display: flex;
  align-items: center;

  &::before {
    content: '📅';
    margin-right: 8px;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px 0;
  background-color: #6c63ff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #5753c9;
  }
`;

// Modal to show all dates
const DateModal = ({ dates, onClose, isVisible }) => {
  const newDatesArray = dates.map((element) => {
    const [from, to] = element.split('to').map((item) => item.trim());
    return { from, to };
  });

  return (
    <>
      <Overlay isVisible={isVisible} onClick={onClose} />
      <ModalContainer isVisible={isVisible}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h3>Available Dates</h3>
        <ul>
          {newDatesArray.map((dateRange, index) => (
            <Dates key={index}>
              <li>{dateRange.from}</li>
              <li>to</li>
              <li>{dateRange.to}</li>
            </Dates>
          ))}
        </ul>
      </ModalContainer>
    </>
  );
};

const TripCardMini = ({ tripDetails }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const pageUrl = `/trip/${tripDetails.id}`;

  return (
    <>
      <Card>
        <Header>
          <Avatar src={tripDetails.host_image} alt="profile image" />
          <Info>
            <div>by {tripDetails.host_username}</div>
            <Location>{tripDetails.city}, {tripDetails.state}</Location>
          </Info>
        </Header>
        <ImageContainer>
          <Image src={tripDetails.photos && tripDetails.photos[0]} alt="Escape to the beauty of Palampur" />
        </ImageContainer>
        <Content>
          <Title>{tripDetails.title}</Title>
          <DateContainer onClick={handleOpenModal}>
            {tripDetails.date && tripDetails.date.length > 0 && tripDetails.date[0]}
            {tripDetails.date.length > 1 ? ` (+${tripDetails.date.length - 1} more)` : ''}
          </DateContainer>
          <Price>{formatINR(tripDetails.price)}</Price>
          <Link to={pageUrl}><Button>View Details</Button></Link>
        </Content>
      </Card>
      <DateModal
        dates={tripDetails.date || []}
        isVisible={isModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default TripCardMini;
