import { styled } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 7vh;
  `;


const Sidebar = styled.div`
  flex: 1;
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
`;

export const LeftSidebar = styled(Sidebar)`
  margin-left: 15%;
  @media (max-width: 1100px) {
    display: none; /* Hide sidebars on mobile */
  }
`;

export const RightSidebar = styled(Sidebar)`
  margin-right: 15%;
  @media (max-width: 768px) {
    display: none; /* Hide sidebars on mobile */
  }
`;


export const MainContent = styled.div`
  flex: 2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  

  // background-color: red;
  @media (max-width: 768px) {
    flex: 1;
    border: none;
    padding: 0;
  }
`;

export const SideBarTopPostContainer = styled.div`
  display: flex; 
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;

`

export const TopPostPageContainer = styled.div`
  display: flex;
  // height: 93vh;
  gap: 40px;
  padding: 20px;  
  box-sizing: border-box;
  overflow-y: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0; /* Remove container padding on mobile */
    justify-content: center;
    align-items: center;
  }
`

export const TopHostsPageContainer = styled.div`
  
`