import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: #f0f0f0;
    font-family: 'Arial', sans-serif;
  }
`;

export default GlobalStyle;
