import React, { useEffect, useState } from 'react';

import BottomBar from '../../navigation/BottomBar';
import { FETCH_TRIP_BY_ID } from '../../../constants';
import Itinerary from "../../ui/iternary/Iternary";
import Navbar from '../../navigation/NavBar';
import { StyledLink } from "../../ui/random";
import TripImageCarousel from "../../ui/carousel/TripImageCarousel";
import { apiHelper } from "../../../utils/apiHelper";
import { formatINR } from '../../../utils/prices';
import { styled } from 'styled-components';
import { useParams } from "react-router-dom";

// Styled components
const ImageCarouselContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  @media (max-width: 906px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 80%;
  display: flex;
  column-gap: 2rem;
  padding: 0 1rem;
  margin: 0 auto;
  @media (max-width: 906px) {
    width: 95%;
  }
  @media (max-width: 790px) {
    flex-direction: column;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
  margin-bottom: 7vh;
`;

const LefContainer = styled.div`
  padding-top: 3rem;
  width: 60%;
  @media (max-width: 906px) {
    width: 100%;
  }

  @media (max-width: 790px) {
    padding-top : 0rem;
  }
`;

const RightContainer = styled.div`
  padding-top: 3rem;
  width: 30%;
  
  @media (max-width: 906px) {
    width: 100%;
  }
  
  @media (max-width: 790px) {
    display: none;
  }

`;

const RightContainerV2 = styled.div`
  padding-top: 3rem;
  width: 30%;
  
  @media (max-width: 790px) {
    padding-top : 1rem;
  }
  @media (max-width: 906px) {
    width: 100%;
  }
  @media (min-width: 790px) {
    display: none;
  }
  

`;

const TextContent = styled.div`
@media (max-width: 790px) {
    padding-top : 1rem;
  }

`;


const Description = styled.p`
  word-spacing: 1.5px;
  font-size: 0.9rem;
  color: #646363;
  padding: 1rem;
  background-color: white;
  margin-top: 1rem;
  @media (max-width: 906px) {
    font-size: 1.1rem;
  }
  @media (max-width: 790px) {
    margin-top : 0rem;
  }
`;

const HostedBy = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 0;
`;

// Price card styling
const PriceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.8rem;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  @media (max-width: 790px) {
    margin-bottom: 1rem;

  }
`;

const StartingPrice = styled.p`
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
`;

const Price = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  margin-top: 0;
`;

const PricePerAdult = styled.span`
  font-size: 12px;
  color: #888;
`;

const DiscountBadge = styled.div`
  background-color: #ff9900;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
`;

// Header styling
const HeaderContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  border-bottom: 1px solid #ccc;
  div{
    display: flex;
    align-items: center;
  }
`;

const VerticalLine = styled.div`
  width: 5px;
  height: 2rem;
  background-color: #ff9900;
  margin-right: 10px;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
`;

const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
    &:hover {
    text-decoration: underline;
  }
  @media (max-width: 906px) {
    font-size: 1.1rem;
  }
`;

// Component implementations
const PriceCard = ({ strikeOffPrice, currentPrice }) => {
  return (
    <PriceCardContainer>
      <StartingPrice>Starting from INR {strikeOffPrice}</StartingPrice>
      <Price>{currentPrice}</Price>
      <PricePerAdult>per Adult</PricePerAdult>
    </PriceCardContainer>
  );
};

const Header = ({ title, hostedBy }) => {
  return (
    <HeaderContainer>
      <div>
        <VerticalLine />
        <div>
          <Title>{title}</Title>
        </div>
      </div>
      <HostedBy>
        Hosted by - <StyledLink to={`/user/${hostedBy}`}><span>{hostedBy}</span></StyledLink>
      </HostedBy>
    </HeaderContainer>
  );
};

const CollapsibleDescription = ({ text, limit = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText = text.length > limit ? text.substring(0, limit) + '...' : text;

  return (
    <>
      <Description>
        {isExpanded ? text : truncatedText}
        {text.length > limit && (
          <ReadMoreButton onClick={toggleDescription}>
            {isExpanded ? "Read Less" : "Read More"}
          </ReadMoreButton>
        )}
      </Description>

    </>
  );
};

function TripDetails() {
  const params = useParams();
  const [tripDetail, setTripDetail] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 549);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageList = isMobile ? tripDetail.photos_small : tripDetail.photos;

  useEffect(() => {
    apiHelper({
      url: `${FETCH_TRIP_BY_ID}/${params.tripId}`,
    })
      .then((response) => {
        setTripDetail(response.data);
      })
      .catch((error) => {
        // Error handling placeholder
      });
  }, [params.tripId]);

  return (
    <>
      <Navbar />
      <ImageCarouselContainer>
        <TripImageCarousel source={"PDP"} images={imageList || []} />
      </ImageCarouselContainer>
      <Container>
        <LefContainer>
          <TextContent>
            <Header title={tripDetail.title} hostedBy={tripDetail.host_username} />
          </TextContent>
          <RightContainerV2>
            <PriceCard strikeOffPrice={formatINR(tripDetail.price)} currentPrice={formatINR(tripDetail.price)} />
          </RightContainerV2>
          <CollapsibleDescription text={tripDetail.description || ""} limit={150} />
          <Itinerary itinerary={tripDetail.itenary} />
        </LefContainer>
        <RightContainer>
          <PriceCard strikeOffPrice={formatINR(tripDetail.price)} currentPrice={formatINR(tripDetail.price)} />
        </RightContainer>
      </Container>
      <BottomBar />
    </>
  );
}

export default TripDetails;
