import axios from "axios";

export async function apiHelper(obj) {
  const {
    url,
    method = 'GET',
    data = {},
    params = {},
    headers = {}
  } = obj;

  const soccalToken = localStorage.getItem("soccalToken");
  headers["Authorization"] = soccalToken

  return axios({
    url,
    method,
    params,
    data,
    headers
  })
    .then((response) => {
      return new Promise((resolve) => {
        resolve({ ...response.data, success: true })
      })
    })
    .catch((error) => {
      // we can add a global popup here to display something went wrong
      return new Promise((resolve, reject) => {
        reject({ ...error, success: false })
      })
    })
}

