import React from 'react'
import styled from 'styled-components';

const Button = styled.button`
  background-color: #4CAF50; /* Green background color */
  border: none; /* No border */
  color: white; /* White text color */
  padding: 15px 32px; /* Padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* No underline */
  display: inline-block; /* Display as inline-block */
  font-size: 16px; /* Font size */
  margin: 4px 2px; /* Margin */
  cursor: pointer; /* Cursor on hover */
  border-radius: 8px; /* Rounded corners */

  /* Hover effect */
  &:hover {
    background-color: #45a049; /* Darker green on hover */
  }
`

const SubmitButton = ({text, onClick}) => {
  return (
    <Button onClick={onClick}>
      {text}
    </Button>
  )
}

export default SubmitButton;
